<template>
    <div class="base-container">
        <el-card class="box-card">
            <!--仲裁评级选择（80-89）-->
            <div slot="header" class="clearfix">
                <span>历史评阅记录</span>
            </div>
            <div class="item">
                <el-table empty-text="暂无历史评阅记录"
                          :data="dataList"
                          class="table-area"
                          style="width: 100%">
                    <el-table-column align="center" prop="number" label="作品编号"></el-table-column>
                    <el-table-column align="center" prop="grade" label="评分"></el-table-column>
                    <el-table-column align="center" label="评阅时间">
                        <template slot-scope="scope">
                            {{formatHandle(scope.row.update_time)}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="作品图片">
                        <template slot-scope="scope">
                            <!--<div class="pic" @click="viewImage(scope.row, scope.$index)">
                                <el-image fit="contain"
                                          :src="$store.getters.getFilePath+scope.row.images"
                                ></el-image>
                            </div>-->
                            <div class="pic" @click="openGiveMark(scope.row, scope.$index)">
                                <el-image fit="contain"
                                          :src="$store.getters.getFilePath+scope.row.images"
                                ></el-image>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作">
                        <template slot-scope="scope">
                            <el-button
                                    type="text"
                                    size="small"
                                    @click="toGiveMark(scope.row, scope.$index)"
                            >评阅
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <el-pagination
                        v-if="total>0"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="query.start"
                        :page-sizes="[10, 20, 30, 50, 100]"
                        :page-size="query.size"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
        </el-card>

        <!--查看作品图片弹框-->
        <el-dialog
                title=""
                :visible.sync="dialogVisible"
                width="720px"
                top="30px"
                class="img-dialog"
                :before-close="handleClose">
            <div class="dialog-header">
                <div class="txt">
                    <span>作品编号：{{dialogData.number}}</span>
                    <span>作品评分：{{dialogData.grade}}</span>
                </div>
                <!--<el-button type="small"
                           class="py-btn"
                           @click="toGiveMark(dialogData, dialogIdx, 1)"
                >评阅
                </el-button>-->
                <el-button type="small"
                           class="py-btn"
                           @click="openGiveMark(dialogData, dialogIdx, 1)"
                >评阅
                </el-button>
            </div>
            <div class="dialog-body">
                <el-image
                        fit="contain"
                        :src="$store.getters.getFilePath+dialogData.images"
                >
                </el-image>
            </div>
        </el-dialog>

        <GiveMarkDialog
                :isTypeFu="2"
                :queryObjFu="queryGMObj"
                :dialogVisibleFu="dialogGMVisible"
                @closeDialog="closeDialog"
        />
    </div>
</template>

<script>
    import {formatTime} from "../../common/utils"
    import {
        getStudentScoreList,
        getStudentScoreList2,
        getStudentScoreListEnd
    } from "../../api/score";
    import GiveMarkDialog from "../../components/GiveMarkDialog";

    export default {
        name: "history",
        components: {GiveMarkDialog},
        data() {
            return {
                query: {
                    start: 1,
                    size: 10,
                    scores: null,
                    order: 2,
                },
                total: 0,
                dataList: [],
                dialogVisible: false,
                dialogData: {},
                dialogIdx: 0,

                dialogGMVisible: false,
                queryGMObj: {},
            }
        },
        created() {
            let start = window.localStorage.getItem('history_start');
            let size = window.localStorage.getItem('history_size');
            if (start && start != 0) {
                this.query.start = Number(start);
            }
            if (size && size != 0) {
                this.query.size = Number(size);
            }
            this.getList();
        },
        methods: {
            formatHandle(val) {
                if (isNaN(val)) {
                    return val;
                }
                if (val.length < 13) {
                    val = val * 1000;
                }
                return formatTime(val);
            },
            // 查看作品-关闭弹框
            handleClose() {
                this.dialogIdx = 0;
                this.dialogData = {};
                this.dialogVisible = false;
            },
            // 查看作品
            viewImage(data, idx) {
                this.dialogData = data;
                this.dialogIdx = idx;
                this.dialogVisible = true;
            },
            // 关闭评阅弹框
            closeDialog(val, type) {
                this.dialogGMVisible = val;
                if (type) {
                    this.getList();
                }
            },
            // 去评阅-图片
            openGiveMark(row, idx, type) {
                let start = (this.query.start - 1) * this.query.size + (idx + 1);
                this.queryGMObj = {
                    start: start,
                    order: this.query.order,
                }
                this.dialogGMVisible = true;
                if (type == 1) {
                    this.handleClose();
                }
            },
            // 去评阅-按钮
            toGiveMark(data, idx, type = 0) {
                let start = (this.query.start - 1) * this.query.size + (idx + 1);
                this.$router.push({
                    path: '/score/giveMark',
                    query: {
                        start: start,
                        order: this.query.order,
                    }
                });
                if (type == 1) {
                    this.handleClose();
                }
                window.localStorage.setItem('history_size', this.query.size);
            },
            handleSizeChange(val) {
                this.score = null;
                this.query.size = val;
                window.localStorage.setItem('history_size', this.query.size);
                this.getList();
            },
            handleCurrentChange(val) {
                this.score = null;
                this.query.start = val;
                window.localStorage.setItem('history_start', this.query.start);
                this.getList();
            },
            getList(type = false) {
                if (this.$store.state.teacherType == 1) {
                    // 阅卷老师
                    this.requestHandle(getStudentScoreList, type)
                } else if (this.$store.state.teacherType == 3) {
                    // 第二轮
                    this.requestHandle(getStudentScoreList2, type)
                }
            },
            requestHandle(api, type) {
                this.dataList = [];
                api(this.query)
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) return this.$message.error(res.data.message)
                        this.total = res.data.data.sum ? res.data.data.sum : res.data.data.count;
                        this.dataList = res.data.data.list;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
        }
    }
</script>

<style scoped lang="scss">
    .base-container {
        width: 100%;
        height: 100%;

        ::v-deep .el-card {
            width: 100%;
            height: 100%;
            display: flex;
            flex-flow: column;
            position: relative;
            padding-bottom: 72px;

            .el-card__header {
                /*height: 60px;*/
                font-size: 20px;
                font-weight: bold;
                line-height: 40px;
                padding: 10px 20px;
            }

            .el-card__body {
                flex: 1;
                display: flex;
                flex-flow: column;
                overflow-y: auto;

                .item {
                    height: 100%;

                    .empty-area {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        padding: 0;
                    }

                    .el-pagination {
                        position: absolute;
                        height: 32px;
                        right: 20px;
                        bottom: 20px;
                    }
                }
            }
        }

        ::v-deep .img-dialog {
            .el-dialog__header,
            .el-dialog__body {
                padding: 0;
            }

            .dialog-header {
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-content: space-between;
                padding: 12px 56px 12px 20px;
                border-bottom: 1px solid #EBEEF5;

                .txt {
                    font-size: 18px;

                    span {
                        margin-right: 20px;
                    }
                }

                .py-btn {
                    font-size: 18px;
                    color: #ffffff;
                    border-color: #409EFF;
                    background-color: #409EFF;

                }
            }

            .dialog-body {
                padding: 20px;
                height: 440px;

                .el-image {
                    width: 100%;
                    height: 100%;

                    > img {
                        max-width: 100% !important;
                        max-height: 100% !important;
                    }
                }
            }
        }
    }

    ::v-deep .table-area {
        width: 100%;
        font-size: 18px;

        .cell {
            line-height: normal;
        }

        .el-table__header {
            th {
                padding: 15px 0;
                color: #333333;
                font-weight: bold;
                background-color: #F0F2F5;
                border-top: 1px solid #EBEEF5;

                &:first-child {
                    border-left: 1px solid #EBEEF5;
                    border-radius: 4px 0 0 4px;
                }

                &:nth-last-child(2) {
                    border-right: 2px solid #EBEEF5;
                    border-radius: 0 4px 4px 0;
                }


            }
        }

        .el-table__body {
            td {
                padding: 10px 0;
            }

            .pic {
                width: 50px;
                height: 50px;
                cursor: pointer;
                border: 1px solid #EBEEF5;
                margin: 0 auto;

                .el-image {
                    width: 100%;
                    height: 100%;
                }

                > img {
                    max-width: 100% !important;
                    max-height: 100% !important;
                }
            }

            .el-button {
                padding: 9px;
                font-size: 18px;

                &:hover {
                    font-weight: bold;
                }
            }
        }
    }
</style>
